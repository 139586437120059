@use "../../../../../../styles/etc/env" as *;
@use "../../../../../../styles/etc/bootstrap.min" as *;
@use "mixins" as *;
@use "animations" as *;

$assetsBaseURL: "#{$s3BaseURL}assets/custom_pages/junior-grizzlies/";
$imageAssetsURL: "#{$assetsBaseURL}img/";
$mttBaseURL: "#{$imageAssetsURL}staff/styled/";
$assetsBaseURLXL: "#{$s3BaseURL}assets/custom_pages/mvprenewals/2024-25/";
$grittyBgLink: "#{$s3BaseURL}assets/img/grizzlies-backgrounds/gritty.jpg";

.grizzYouthGlobalStyles {
	:global {
		@import "../../../../../../styles/etc/utilities";

		div[class*="css_NBAPageContent_"] {
			margin: 0;
			padding: 0;
		}

		.collapse {
			visibility: inherit;
		}
	}
}

.grizzYouthStyles {
	@include bs5;

	:global {
		.gap-custom-4 {
			@media (min-width: 768px) {
				gap: 2rem;
			}
		}

		main {
			font-size: 62.5%;
			font-family: "Praktika Medium", Arial, Helvetica, sans-serif;
			font-weight: 500;

			.main-title {
				position: relative;
				background: linear-gradient(139deg, rgba(12, 35, 64, 0.8) 25%, rgba(12, 35, 64, 0.15) 100%);
				color: #fff;
				padding-top: 3.75rem;
				padding-right: 1rem;
				padding-bottom: 3.75rem;
				padding-left: 1rem;

				&::before {
					content: "";
					position: absolute;
					z-index: -1;
					display: block;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					background-image: url("#{$imageAssetsURL}20221009_Grizzlies_JrNBAClinic_068.jpg");
					background-size: cover;
					background-repeat: no-repeat;
					background-attachment: scroll;
				}

				.main-title-content {
					max-width: 25rem;
					text-align: center;
					margin-right: auto;
					margin-left: auto;

					.junior-grizzlies-logo {
						margin-bottom: 1.25rem;
					}

					p {
						font-size: 1rem;
					}

					.associate-partner {
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-top: 1.25rem;
						margin-bottom: 2rem;

						p {
							font-family: "Praktika Medium Condensed", Arial, Helvetica, sans-serif;
							text-transform: uppercase;
							font-size: 0.875rem;
							letter-spacing: 0.25px;
							margin-bottom: 0.5rem;
						}

						svg,
						img {
							width: 8.75rem;
						}
					}
				}
			}

			a {
				font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
				color: $bealeStreetBlue;
				font-weight: initial;
				text-decoration: none;

				&:hover {
					color: $bealeStreetBlue;
					text-decoration: underline;
				}

				&:hover,
				&:focus,
				&:active {
					box-shadow: none !important;
					outline: none !important;
				}
			}

			h2 {
				font-family: "Praktika Black Extended", Arial, Helvetica, sans-serif;
				text-transform: uppercase;
				margin: 0;
				text-align: center;
				font-size: 2.75em;
				line-height: 1;
				padding: 0 0 0.8em;
			}

			h3 {
				font-family: "Praktika Medium", Arial, Helvetica, sans-serif;
				line-height: 1;
				font-size: 1.8em;
				margin: 0;
			}

			p {
				font-size: 1.6em;
				line-height: 1.3;
				text-align: center;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.sep {
				height: 4px;
				background: transparent
					linear-gradient(90deg, #0c2340 0%, #7d98c1 30%, #ffc72c 58%, #0c2340 78%, #7d98c1 100%) 0%
					0% no-repeat padding-box;
			}

			.yl,
			.bsbl {
				width: 75%;
				height: 1px;
				background: #ffc72c 0% 0% no-repeat padding-box;
				margin: 0 auto;
			}

			.bsbl {
				background: #7d98c1 0% 0% no-repeat padding-box;
				margin-left: 0;
			}

			section {
				padding: 2.4em 1.6em;

				> .grid {
					display: grid;
					grid-template-columns: 1fr;
					gap: 2em;
				}

				&.camps-clinics {
					> .grid {
						gap: 3rem;

						> div {
							p {
								font-size: 1.425em;
							}

							&.has-events {
								ul {
									padding: 0;
									margin: 0;

									li {
										& + li {
											padding-top: 1em;
										}

										h3 {
											margin-bottom: 0.2em;
										}

										time {
											display: block;
											font-size: 1.4em;

											span {
												font-weight: 500;
											}

											&:nth-child(2) {
												font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
												font-weight: initial;
												text-transform: uppercase;
												font-size: 2.4em;
												line-height: 1;
												margin-bottom: 0.2em;
											}
										}

										a {
											font-weight: initial;
											text-decoration: underline;
											position: relative;
											display: inline-flex;
											align-items: center;
											color: #0c2340;
											font-family: "Praktika Medium", Arial, Helvetica, sans-serif;

											&::before {
												content: "";
												position: absolute;
												bottom: -0.12em;
												right: 0;
												left: 0;
												background-color: #fff;
												height: 1px;
												opacity: 0;
												transition: opacity 0.3s ease-in-out;
											}

											&:hover {
												&::before {
													opacity: 0.5;
												}
											}

											&[class*="css_actionButton__"] {
												font-weight: 700;
											}

											span {
												padding-right: 0.25em;
												font-weight: bold;
											}

											svg {
												padding-left: 0.25em;
											}
										}
									}
								}

								.multiLocations {
									margin: 20px 0px 10px 0px;

									ul {
										font-size: 1.4em;
										padding: 0;

										li {
											& + li {
												padding-top: 0.5em;
											}

											span {
												font-weight: bold;
												padding-right: 0.25em;
											}
										}
									}
								}
							}

							&.camps {
								&::before {
									background-attachment: fixed;
								}

								&::after {
									content: "";
									position: absolute;
									right: 0;
									bottom: 0;
									left: 0;
									top: 0;
									background: #fff;
									z-index: -1;
									opacity: 0.86;
									background-attachment: fixed;
								}

								p {
									font-weight: normal;
								}
							}

							&.clinics {
								p {
									font-weight: normal;
								}
							}
						}
					}
				}

				&.nrg {
					h2,
					hr,
					p {
						max-width: 992px;
						margin-left: auto;
						margin-right: auto;
					}

					h2 {
						padding-bottom: 1.7rem;
					}

					hr {
						width: 75%;
						margin-top: 0;
						margin-bottom: 1.8rem;
					}

					@media (max-width: 576px) {
						.ratio {
							&::before {
								aspect-ratio: 0.7;
							}
						}
					}
				}

				&.meet-the-team {
					background-color: $memphisMidnight;
					padding-right: 0;
					padding-left: 0;

					:global {
						.scrolling-wrapper {
							overflow-x: auto;
							padding-bottom: 1px;

							.team-item {
								box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.15);

								&[class~="corey-elliott.jpg"] {
									background-image: url("#{$mttBaseURL}corey-elliott.jpg");
								}

								&[class~="ronni-williams-4.jpg"] {
									background-image: url("#{$mttBaseURL}ronni-williams-4.jpg");
								}
							}
						}
					}
				}

				&.faq {
					.accordion-outer {
						.faq-addon {
							a {
								font-weight: initial;
							}

							> p {
								font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
								font-weight: initial;
								text-align: left;

								&.small {
									font-family: "Praktika Medium", Arial, Helvetica, sans-serif;
								}
							}
						}
					}

					.accordion {
						.accordion-item {
							background: transparent;

							h2 {
								margin: 0;
							}

							border-bottom: 1px $memphisMidnight solid;
						}

						.accordion-header {
							button {
								color: $memphisMidnight;
								background: transparent;
								font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
								font-weight: initial;
								font-size: 1.2rem !important;
								padding: 0.5rem;
								padding-left: 0;
								padding-right: 0;
								box-shadow: none;

								&:focus,
								&:active,
								&:hover {
									outline: none !important;
									box-shadow: none !important;
								}

								&::after {
									background-image: url("#{$imageAssetsURL}chevron-right.png");
									background-size: contain;
									background-position: center;
									background-repeat: no-repeat;
									width: 1rem;
									height: 1rem;
									filter: invert(100%);
								}
							}

							button[aria-expanded="true"] {
								&::after {
									transform: rotate(0.25turn);
								}
							}

							padding-top: 0.5rem;
							padding-bottom: 0.5rem;
						}

						.accordion-body {
							padding-left: 0;

							ul {
								position: relative;
								padding-bottom: 0.5rem;
								margin-top: -0.5rem;
								margin-bottom: 0;
								padding-left: 0;

								li {
									line-height: 1.3;
									position: relative;
									font-size: 1.1rem !important;
									color: $memphisMidnight;

									a {
										font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
										font-weight: initial;
										white-space: nowrap;
									}
								}
							}
						}
					}
				}
			}
		}

		.meet-the-team {
			.team-item {
				.meta {
					.name {
						font-family: "Praktika Black", Arial, Helvetica, sans-serif;
						letter-spacing: 1px;
					}

					.title {
						font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
						font-weight: initial;
					}

					.email {
						font-family: "Praktika Medium", Arial, Helvetica, sans-serif;
					}
				}
			}
		}
	}

	.leaguesMapContainer {
		position: relative;
		aspect-ratio: 3 / 4;

		:global {
			.overlay {
				position: absolute;
				content: "";
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}

		iframe {
			width: 100%;
			height: 100%;
		}
	}

	.campaignSection {
		padding: 0 0 2.5rem;

		.campaignSectionTitle {
			border-top: 0.25rem solid $memphisMidnight;
			border-bottom: 0.25rem solid $memphisMidnight;
			margin-bottom: 2.5rem;

			.marquee {
				padding-top: 1rem;
				padding-bottom: 1rem;

				:global {
					.rfm-child {
						padding-left: 2rem;
						padding-right: 2rem;

						h2 {
							font-size: 1.5rem;
						}

						&:nth-child(1n) {
							h2 {
								font-family: "Praktika Extra Bold Extended Italic", Arial, Helvetica, sans-serif;
							}
						}

						&:nth-child(2n) {
							h2 {
								font-family: "Praktika Bold Extended", Arial, Helvetica, sans-serif;
								margin-bottom: 3px;
							}
						}
					}
				}

				.marqueeItem {
					padding-bottom: 0;
				}
			}
		}

		.campaignSectionBody {
			padding-right: 1rem;
			padding-left: 1rem;
		}

		.separator {
			background-color: $memphisMidnight;
			height: 0.25rem;
			width: 12.5rem;
			margin-top: 2rem;
			margin-bottom: 2rem;
		}
	}

	.campaignGrid {
		display: grid;
		grid-template-columns: 1fr;
		row-gap: 2rem;
	}

	.campaignSectionBlock {
		.campaignHeader {
			margin-bottom: 1.5rem;

			h3 {
				font-family: "Praktika Extra Bold", Arial, Helvetica, sans-serif;
				font-size: 1.5rem;
				letter-spacing: 0.15px;
				text-transform: uppercase;
				margin-bottom: 0.5rem;
				text-align: center;
				margin-bottom: 0.5rem;
			}
		}
	}

	.eventsGrid {
		display: grid;
		grid-template-columns: 1fr;
		column-gap: 2rem;
		row-gap: 1.25rem;
	}

	.eventBlock {
		background-color: #fff;
		border: 2px solid $memphisMidnight;
		outline: 2px solid transparent;
		border-radius: 0.5rem;
		transition: outline 0.3s ease-in-out;
		align-self: flex-start;

		&.open {
			outline-color: $memphisMidnight;
			align-self: stretch;
		}

		&.soldOut {
			border-color: $gray;
			color: $gray;
			opacity: 0.8;

			.eventBlockTrigger {
				cursor: default;
				user-select: none;
			}
		}

		:global {
			h4 {
				font-family: "Praktika Extra Bold", Arial, Helvetica, sans-serif;
				font-size: 1.35rem;
				letter-spacing: 0.15px;
				text-transform: uppercase;
				margin-bottom: 0.5rem;
			}
		}

		.eventBlockTrigger {
			cursor: pointer;
			padding-top: 1rem;
			padding-right: 1rem;
			padding-bottom: 1rem;
			padding-left: 1rem;

			strong {
				font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
			}
		}

		.eventBlockCollapse {
			padding-top: 0.5rem;
		}

		.eventBlockListItem {
			display: flex;
			align-items: center;
			gap: 1.125rem;
			font-family: "Praktika Medium", Arial, Helvetica, sans-serif;
			font-size: 1rem;
			width: fit-content;
			color: currentColor !important;

			& + .eventBlockListItem {
				margin-top: 0.5rem;
			}
		}

		.helpText {
			color: $gray;
			font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
			font-size: 0.875rem;
			margin-top: 0.75rem;
			margin-bottom: 1.5rem;
		}

		.actionButton {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 1rem;
			background-color: $gold;
			color: $memphisMidnight;
			font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
			font-size: 1rem;
			text-transform: uppercase;
			text-decoration: none;
			min-width: 12.5rem;
			padding: 0.5rem 1rem;
			border: 0.15rem solid $gold;
			border-radius: 0.5rem;
			transition: all 0.3s ease-in-out;
			margin-top: 1rem;

			.disabled {
				border: $gray;
				background-color: $gray;
				color: #000;
				opacity: 0.75;
			}

			&:hover {
				background-color: #fff;
			}
		}
	}

	.formContainer {
		font-size: 1.2rem;

		h2 {
			padding: 0;
		}

		:global {
			div[class*="css_form__"] {
				max-width: 992px;
			}

			form {
				input {
					border: 0.1875rem solid $memphisMidnight;
					border-radius: 0;
				}

				.form-label {
					font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
					text-transform: uppercase !important;
				}

				.form-check {
					padding-left: 2.25rem;

					.form-check-input {
						height: 1.5rem;
						width: 1.5rem;
						margin-top: 0.125rem;
						margin-left: -2.25rem;

						&:checked {
							background-color: $memphisMidnight;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 576px) {
	.grizzYouthStyles {
		.leaguesMapContainer {
			aspect-ratio: 1 / 1;
		}
	}
}

@media (min-width: 768px) {
	.grizzYouthStyles {
		:global {
			main {
				.main-title {
					padding-right: 1.25rem;
					padding-left: 1.25rem;

					.main-title-content {
						.junior-grizzlies-logo {
							margin-bottom: 2rem;
						}

						p {
							font-size: 1.25rem;
						}
					}
				}

				.bsbl {
					width: 50%;
				}

				section {
					&.camps-clinics {
						> .grid {
							gap: 5em;
						}

						.camps {
							> div {
								flex-wrap: wrap;
							}

							.camp-outer {
								width: 100%;

								.camp {
									max-width: initial;

									h3 {
										span {
											display: inline !important;
										}
									}
								}
							}
						}
					}

					&.meet-the-team {
						padding-right: 0.25rem;
						padding-left: 0.25rem;
					}
				}
			}
		}

		.campaignSection {
			padding: 0 0 4rem;

			.campaignSectionTitle {
				margin-bottom: 3.75rem;
			}

			.campaignSectionBody {
				padding-right: 1.25rem;
				padding-left: 1.25rem;
			}

			&.leagues {
				.leaguesMapContainer {
					aspect-ratio: 16 / 9;
				}
			}
		}

		.eventBlock {
			.actionButton {
				width: fit-content;
			}
		}

		.formContainer {
			:global {
				form {
					.row {
						> div {
							&:nth-child(1),
							&:nth-child(2) {
								flex: 0 0 auto;
								width: 50%;
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 992px) {
	.grizzYouthStyles {
		:global {
			main {
				.main-title {
					padding-top: 7.875rem;
					padding-left: 4rem;
					padding-bottom: 7.875rem;

					.main-title-content {
						margin-right: unset;
						margin-left: unset;
					}
				}

				section {
					&.meet-the-team {
						:global {
							.scrolling-wrapper {
								justify-content: center;

								> div {
									flex: 0 1 33.3333%;
									width: initial;
								}
							}
						}
					}
				}
			}
		}

		.campaignSection {
			&.leagues {
				p {
					text-align: left;
				}

				.leaguesGrid {
					display: grid;
					grid-template-columns: 7fr 8fr;
					gap: 3.75rem;
				}
			}
		}

		.eventsGrid {
			grid-template-columns: repeat(2, 1fr);

			.eventBlock {
				&:only-child {
					grid-column: 1 / 3;
				}
			}
		}
	}
}

@media (min-width: 1200px) {
	.grizzYouthStyles {
		:global {
			main {
				.main-title {
					padding-top: 10rem;
					padding-bottom: 10rem;

					.main-title-content {
						margin-right: unset;
						margin-left: unset;
					}
				}

				h2 {
					font-size: 4em;
				}

				section {
					padding: 4em 1.6em;

					> .grid {
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						gap: 4em;
						max-width: 1200px;
						margin: 0 auto;
					}

					&.msn {
						padding-bottom: 5em;

						> div {
							grid-template-columns: 1fr 3fr 0.5fr;

							img {
								align-self: center;
								justify-self: center;
								width: 80%;
								grid-column: 1;
							}
						}
					}

					&.camps-clinics {
						padding-bottom: 2em;

						> .grid {
							max-width: 1400px;
							gap: 8em;

							> div {
								&.has-events {
									> ul {
										font-size: 110%;

										li {
											padding-bottom: 2.5em;
										}
									}
								}

								&.camps {
									padding-bottom: 4em;

									> div {
										gap: 4em;

										> div {
											align-self: center;

											.nt {
												margin-bottom: 0;
											}
										}
									}
								}
							}
						}
					}

					&.nrg {
						padding-bottom: 5em;
					}

					&.faq {
						max-width: initial;

						> div {
							max-width: 992px;
							margin: 0 auto;
						}

						.accordion {
							.accordion-header {
								button {
									font-size: 1.6rem;
								}
							}

							.accordion-body {
								ul {
									li {
										font-size: 1.4rem;

										&::before {
											top: 0.45rem;
										}
									}
								}
							}
						}

						.faq-block {
							max-width: 1200px;
							display: flex;
							gap: 3rem;

							.faq-img {
								width: 40%;
								background-image: url("#{$imageAssetsURL}_0327022_VIP_Hoop_School_JM088.jpg");
								background-size: 110%;
								background-repeat: no-repeat;
								background-position-y: -250px;
								box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
								border-radius: 0.25rem;
								min-height: 500px;
							}

							.accordion-outer {
								width: 60%;

								margin-top: -0.75rem;
							}
						}
					}
				}
			}
		}

		.campaignSection {
			.campaignSectionTitle {
				.marquee {
					padding-top: 1.25rem;
					padding-bottom: 1.25rem;

					:global {
						.rfm-child {
							padding-left: 3.125rem;
							padding-right: 3.125rem;

							h2 {
								font-size: 2.5rem;
							}
						}
					}

					.marqueeItem {
						padding-bottom: 0;
					}
				}
			}
		}
	}
}

.camp {
	max-width: 1200px;
	margin: 1rem auto 2rem;

	:global {
		h2 {
			padding-bottom: 1.5rem !important;
		}

		hr {
			opacity: initial;
			border-bottom: 1px $memphisMidnight solid;
			width: 75%;
			margin: 0 auto 1.5rem;
			opacity: 0.5;
		}

		.h {
			margin-bottom: 2rem;

			> p {
				&:last-child {
					font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
				}
			}
		}

		.camps {
			position: relative;
			margin-top: 1.5rem;

			> div {
				.camp-outer {
					@media (min-width: 1200px) {
						width: calc(50% - 2rem) !important;
					}
				}
			}

			.camp {
				user-select: none;
				display: flex;
				flex-direction: column;
				gap: 0.75rem;
				margin-bottom: 1rem;
				padding-bottom: 1.75rem;
				border-bottom: 1px $memphisMidnight solid;

				& > a + h3 + div {
					a {
						text-decoration: underline;
						text-underline-offset: 3px;
					}
				}

				h3 {
					position: relative;
					font-family: "Praktika Extra Bold", Arial, Helvetica, sans-serif;
					font-weight: initial;
					text-transform: uppercase;
					letter-spacing: 0.02rem;
					margin-top: 0.5rem;
					padding-left: 2.5rem;

					&::before {
						position: absolute;
						content: "";
						top: calc(50% - 1rem);
						left: 0;
						width: 2rem;
						height: 2rem;
						background-image: url("#{$imageAssetsURL}MG_Secondary Claw Ball Logo_Full Color_PMS.png");
						background-size: 2rem;
						background-position: left top;
						background-repeat: no-repeat;
						z-index: 1;
					}
				}

				> div {
					display: flex;
					align-items: center;
					color: $memphisMidnight;
					font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
					font-weight: initial;
					font-size: 1rem;
					line-height: 1.2;

					a {
						font-family: "Praktika Medium", Arial, Helvetica, sans-serif;
						color: $memphisMidnight;
					}

					&.price {
						font-size: 1.1rem;

						svg {
							transform: scale(1.4);
						}

						span {
							padding-left: 0.12rem;
							padding-top: 0.04rem;
						}
					}

					svg {
						transform: scale(1.2);
						transform-origin: left center;
						display: inline-block;
						margin-right: 1.1rem;
						margin-left: 0.45rem;
					}

					&:last-child {
						margin-top: 0.25rem;

						svg {
							transform: rotate(45deg) scale(2) translateY(-1px);
							transform-origin: center;
						}

						text-transform: uppercase;

						a {
							font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
							font-weight: initial;
							display: inline-block;
						}
					}
				}

				// &:nth-last-child(3) {
				// 	border-bottom: none;
				// 	padding-bottom: 0;
				// }
			}

			.extra {
				font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
				font-size: 1rem;
				line-height: 1.2;
				margin-bottom: 1rem;
			}

			.swiper-thumbs {
				.camp {
					border-bottom: none;
					padding-bottom: 0;
				}
			}

			&.no-slider {
				@media (min-width: 768px) {
					// >div>div {
					// 	margin: 0 auto;
					// }

					.camp {
						max-width: 50vw;
					}
				}
			}

			&.items-1 {
				.camp-outer {
					.camp {
						&:nth-child(2) {
							border-bottom: none;
							margin-bottom: 0;
							padding-bottom: 0;

							> div {
								&:last-child {
									a {
										@include cta;
										margin-top: 1rem;
										margin-left: 0;
										margin-right: auto;
										padding-right: 2.65rem;
									}
								}
							}
						}
					}

					> div {
						&:last-child.py-2 {
							display: none;
						}
					}
				}

				@media (min-width: 1200px) {
					.camp-outer {
						flex-wrap: wrap;
						flex-direction: row !important;
						gap: 2rem;
						margin-left: 0;
						margin-right: 0;

						.camp {
							width: 100%;

							&:nth-child(2) {
								padding-bottom: 0;
								border-bottom: none;

								> div {
									font-size: 1.2rem;
								}

								h3 {
									font-size: 2.4rem;
									padding-left: 3rem;
									margin-bottom: 0.25rem;

									&::before {
										$clawSize: 2.2rem;
										background-size: $clawSize;
										width: $clawSize;
										height: $clawSize;
									}
								}

								svg {
									transform: scale(1.3);
								}
							}
						}
					}
				}
			}
		}

		.swiper {
			.swiper-wrapper {
				padding-top: 0;
				padding-bottom: 0;
			}

			.swiper-button-prev,
			.swiper-button-next {
				top: calc(50% - 0.75rem);
				bottom: auto;
				width: 3rem;
				height: 3rem;
				opacity: 0.9;

				&::after {
					background-image: url("#{$imageAssetsURL}caret-right-fill.svg");
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					filter: invert(100%);
					color: transparent;
					width: 100%;
					height: 100%;
				}

				&.swiper-button-disabled {
					opacity: 0.3;
					pointer-events: initial;
				}
			}

			.swiper-button-prev {
				&::after {
					transform: rotate(180deg);
				}
			}
		}

		.actions {
			text-align: center;
			margin-top: 2rem;

			> a {
				@include cta;
			}
		}

		@media (min-width: 992px) {
			.h {
				margin: 0 auto 3rem;
				max-width: 1200px;
				display: flex;
				flex-direction: column;
				align-items: center;

				> * {
					max-width: 992px;
				}
			}
		}
	}
}
